import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table, Typography, Spin } from 'antd';
import { ActivityStats, activityService } from '../../api/activity';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const { Title } = Typography;

const Dashboard: React.FC = () => {
  const [stats, setStats] = useState<ActivityStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchStats = async () => {
    try {
      setLoading(true);
      const data = await activityService.getStats();
      setStats(data);
      setError(null);
    } catch (err) {
      setError('Fehler beim Laden der Statistiken');
      console.error('Error fetching stats:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
    // Aktualisiere alle 30 Sekunden
    const interval = setInterval(fetchStats, 30000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin tip="Lade Statistiken..." />
      </div>
    );
  }

  if (error || !stats) {
    return <Typography.Text type="danger">{error}</Typography.Text>;
  }

  // Prepare data for the chart
  const chartData = [
    { name: 'Uploads', value: stats.totalUploads, color: '#1890ff' },
    { name: 'Smoothing', value: stats.totalSmoothings, color: '#52c41a' },
    { name: 'Exporte', value: stats.totalExports, color: '#faad14' },
  ];

  const userColumns = [
    {
      title: 'Benutzer',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Uploads',
      dataIndex: 'uploads',
      key: 'uploads',
      align: 'right' as const,
    },
    {
      title: 'Smoothing',
      dataIndex: 'smoothings',
      key: 'smoothings',
      align: 'right' as const,
    },
    {
      title: 'Exporte',
      dataIndex: 'exports',
      key: 'exports',
      align: 'right' as const,
    },
    {
      title: 'Gesamt',
      dataIndex: 'totalActivities',
      key: 'totalActivities',
      align: 'right' as const,
    },
  ];

  const activityColumns = [
    {
      title: 'Zeitpunkt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => format(new Date(text), 'dd.MM.yyyy HH:mm', { locale: de }),
    },
    {
      title: 'Benutzer',
      dataIndex: ['userId', 'email'],
      key: 'email',
    },
    {
      title: 'Aktion',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (details: any) => details ? JSON.stringify(details, null, 2) : '-',
    },
  ];

  return (
    <div style={{ padding: '24px' }}>
      <Title level={2}>Admin Dashboard</Title>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Aktivitätsübersicht">
            <div style={{ width: '100%', height: 300 }}>
              <ResponsiveContainer>
                <BarChart data={chartData} margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip 
                    formatter={(value: number) => [`${value} Aktionen`, '']}
                    labelStyle={{ color: '#000' }}
                  />
                  <Bar 
                    dataKey="value" 
                    fill="#8884d8"
                    radius={[4, 4, 0, 0]}
                    fillOpacity={0.8}
                  >
                    {
                      chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))
                    }
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Top 5 Aktivste Benutzer">
            <Table
              columns={userColumns}
              dataSource={stats.userStats}
              pagination={false}
              rowKey="userId"
            />
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Letzte Aktivitäten">
            <Table
              columns={activityColumns}
              dataSource={stats.recentActivities}
              pagination={{ pageSize: 10 }}
              rowKey={(record, index) => index?.toString() || '0'}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
