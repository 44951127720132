import { JsonResponse } from '@ppulwey/opttocut-library';
import { Service } from '@ppulwey/opttocut-library';
import { httpClient } from '../client';

export interface UserStats {
  userId: string;
  email: string;
  totalActivities: number;
  uploads: number;
  smoothings: number;
  exports: number;
}

export interface ActivityStats {
  totalUploads: number;
  totalSmoothings: number;
  totalExports: number;
  recentActivities: Array<{
    action: 'FILE_UPLOAD' | 'SMOOTHING' | 'EXPORT';
    details?: any;
    createdAt: string;
    userId: {
      email: string;
    };
  }>;
  userStats: UserStats[];
}

interface IActivity {
  action: 'FILE_UPLOAD' | 'SMOOTHING' | 'EXPORT';
  details?: {
    fileName?: string;
    fileSize?: number;
    parameters?: object;
    format?: string;
    [key: string]: any;
  };
}

class ActivityService extends Service<IActivity> {
  constructor() {
    super('activity', httpClient);
  }

  async getStats(): Promise<ActivityStats> {
    const response = await httpClient.get<JsonResponse<ActivityStats>>(
      '/admin/activities'
    );
    return response.data.result;
  }

  async trackFileUpload(fileName: string, fileSize: number): Promise<void> {
    await this.post(
      {
        action: 'FILE_UPLOAD',
        details: {
          fileName,
          fileSize,
        },
      },
      'track'
    );
  }

  async trackExport(exportFileName: string): Promise<void> {
    await this.post(
      {
        action: 'EXPORT',
        details: {
          fileName: exportFileName,
        },
      },
      'track'
    );
  }
}

export const activityService = new ActivityService();
